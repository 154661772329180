import React, { useState, useEffect } from 'react';
import {
  Link
} from "react-router-dom";
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Tabs, Typography } from '@arco-design/web-react';
const TabPane = Tabs.TabPane;
const AcquireDF = () => {
  const intl = useIntl();
  const fmt = intl.formatMessage;
  const [menu, setMenu] = useState(false)
  const [activeTab, setActiveTab] = useState('2');
  const showMenu = () => {
    setMenu(!menu)
  }
  return (
    <>
      <Container>
        <HeaderWarp>
          <Header>
            <TopBar>
              <a href="https://www.dforce.network/" rel="noopener noreferrer">
                <Log src="/images/logo.svg" />
              </a>
              <Nav>
                <ChangeLanguage
                >
                  <span>Products</span>
                  <Changelist className={'showList'}>
                    <a href='https://usx.finance/' target='_blank' rel="noreferrer">USX Finance</a>
                    <a href="https://unitus.finance" target='_blank' rel="noreferrer">Unitus Finance</a>
                    <a href="https://bridge.dforce.network" target='_blank' rel="noreferrer">Bridge</a>
                    <a href="https://app.dforce.network" target='_blank' rel="noreferrer">Legacy dApp</a>
                  </Changelist>
              </ChangeLanguage>
                <ChangeLanguage
                >
                  <span>Community</span>
                  <Changelist className={'showList'}>
                    <a href="https://twitter.com/dForcenet" target="_blank" rel="noopener noreferrer">X</a>
                    <a href="https://t.me/dforcenet" target="_blank" rel="noopener noreferrer">Telegram</a>
                    <a href="https://discord.com/invite/c2PC8SM" target="_blank" rel="noopener noreferrer">Discord</a>
                    <a href="https://github.com/dforce-network" target="_blank" rel="noopener noreferrer">GitHub</a>
                  </Changelist>
                </ChangeLanguage>
                <ChangeLanguage
                >
                  <span>Governance</span>
                  <Changelist className={'showList'}>
                    <a href="https://snapshot.org/#/dforcenet.eth" target="_blank" rel="noopener noreferrer">Snapshot</a>
                    <a href="https://forum.dforce.network/" target="_blank" rel="noopener noreferrer">Forum</a>
                    <a href="https://gov.dforce.network/#/StakeDF" target="_blank" rel="noopener noreferrer">Staking</a>
                    <a><Link to="/AcquireDF">Acquire DF</Link></a>
                  </Changelist>
                </ChangeLanguage>
                <ChangeLanguage
                >
                  <span>About</span>
                  <Changelist className={'showList'}>
                    <a href="https://docs.dforce.network/" target="_blank" rel="noopener noreferrer">Docs</a>
                    <a href="https://medium.com/dforcenet" target="_blank" rel="noopener noreferrer">Blog</a>
                    <a href="https://github.com/dforce-network/recruitment" target="_blank" rel="noopener noreferrer">Careers</a>
                    <a href="mailto:contact@dforce.network" target="_blank" rel="noopener noreferrer">contact</a>
                  </Changelist>
                </ChangeLanguage>
              </Nav>
              <Menu onClick={showMenu}>
                <img src="/images/m-menu.svg" alt="" />
              </Menu>
            </TopBar>
          </Header>
        </HeaderWarp>
        <Ecosystem>
          <ExTitle>DEX and Bridges<a name="a"></a></ExTitle>
          <Tabs activeTab={activeTab} onChange={setActiveTab} animation>
            <TabPane key='1' title='Ethereum'>
              <Typography.Paragraph>
                <EcoList>
                  <EcoItem href="https://app.1inch.io/#/1/simple/swap/USX/DF" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/1inch.svg" alt="1inch" />
                    <EcoName>1inch</EcoName>
                  </EcoItem>
                  <EcoItem href="https://app.uniswap.org/explore/tokens/ethereum/0x431ad2ff6a9C365805eBaD47Ee021148d6f7DBe0?inputCurrency=0x0a5E677a6A24b2F1A2Bf4F3bFfC443231d2fDEc8" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/Uniswap.svg" alt="Uniswap" />
                    <EcoName>Uniswap</EcoName>
                  </EcoItem>
                  <EcoItem href="https://cbridge.celer.network/1/42161/DF" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/cBridge.svg" alt="cBridge" />
                    <EcoName>cBridge</EcoName>
                  </EcoItem>
                </EcoList>
              </Typography.Paragraph>
            </TabPane>
            <TabPane key='2' title='Arbitrum'>
              <Typography.Paragraph>
                <EcoList>
                  <EcoItem href="https://app.1inch.io/#/42161/simple/swap/USX_1/DF" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/1inch.svg" alt="1inch" />
                    <EcoName>1inch</EcoName>
                  </EcoItem>
                  <EcoItem href="https://app.camelot.exchange/" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/Camelot.svg" alt="Camelot" />
                    <EcoName>Camelot</EcoName>
                  </EcoItem>
                  <EcoItem href="https://app.dodoex.io/swap/network/arbitrum/42161-USX/42161-DF" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/DODO.svg" alt="DODO" />
                    <EcoName>DODO</EcoName>
                  </EcoItem>
                  <EcoItem href="https://cbridge.celer.network/42161/10/DF" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/cBridge.svg" alt="cBridge" />
                    <EcoName>cBridge</EcoName>
                  </EcoItem>
                  <EcoItem href="https://bridge.arbitrum.io/" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/ArbitrumBridge.svg" alt="Arbitrum Bridge" />
                    <EcoName>Arbitrum Bridge</EcoName>
                  </EcoItem>
                </EcoList>
              </Typography.Paragraph>
            </TabPane>
            <TabPane key='3' title='Optimism'>
              <Typography.Paragraph>
                <EcoList>
                  <EcoItem href="https://app.1inch.io/#/10/simple/swap/USX/DF" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/1inch.svg" alt="1inch" />
                    <EcoName>1inch</EcoName>
                  </EcoItem>
                  <EcoItem href="https://velodrome.finance/swap?from=0xbfd291da8a403daaf7e5e9dc1ec0aceacd4848b9&to=0x9e5aac1ba1a2e6aed6b32689dfcf62a509ca96f3" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/Velodrome.svg" alt="Velodrome" />
                    <EcoName>Velodrome</EcoName>
                  </EcoItem>
                  <EcoItem href="https://cbridge.celer.network/10/42161/DF" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/cBridge.svg" alt="cBridge" />
                    <EcoName>cBridge</EcoName>
                  </EcoItem>
                  <EcoItem href="https://app.optimism.io/bridge/deposit" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/OptimismBridge.svg" alt="Optimism Bridge" />
                    <EcoName>Optimism Bridge</EcoName>
                  </EcoItem>
                </EcoList>
              </Typography.Paragraph>
            </TabPane>
            <TabPane key='4' title='BSC'>
              <Typography.Paragraph>
                <EcoList>
                  <EcoItem href="https://app.1inch.io/#/56/simple/swap/USX/DF" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/1inch.svg" alt="1inch" />
                    <EcoName>1inch</EcoName>
                  </EcoItem>
                  <EcoItem href="https://app.dodoex.io/swap/network/bsc-mainnet/56-USX/56-DF" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/DODO.svg" alt="DODO" />
                    <EcoName>DODO</EcoName>
                  </EcoItem>
                  <EcoItem href="https://pancakeswap.finance/swap?inputCurrency=0xB5102CeE1528Ce2C760893034A4603663495fD72&outputCurrency=0x4A9A2b2b04549C3927dd2c9668A5eF3fCA473623" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/Pancakeswap.svg" alt="Pancakeswap" />
                    <EcoName>Pancakeswap</EcoName>
                  </EcoItem>
                  <EcoItem href="https://cbridge.celer.network/56/42161/DF" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/cBridge.svg" alt="cBridge" />
                    <EcoName>cBridge</EcoName>
                  </EcoItem>
                </EcoList>
              </Typography.Paragraph>
            </TabPane>
            <TabPane key='5' title='Polygon'>
              <Typography.Paragraph>
                <EcoList>
                  <EcoItem href="https://app.1inch.io/#/137/simple/swap/USX/DF" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/1inch.svg" alt="1inch" />
                    <EcoName>1inch</EcoName>
                  </EcoItem>
                  <EcoItem href="https://quickswap.exchange/#/swap?swapIndex=0&currency0=0xCf66EB3D546F0415b368d98A95EAF56DeD7aA752&currency1=0x08C15FA26E519A78a666D19CE5C646D55047e0a3" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/Quickswap.svg" alt="Quickswap" />
                    <EcoName>Quickswap</EcoName>
                  </EcoItem>
                  <EcoItem href="https://cbridge.celer.network/137/42161/DF" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/cBridge.svg" alt="cBridge" />
                    <EcoName>cBridge</EcoName>
                  </EcoItem>
                  <EcoItem href="https://portal.polygon.technology/bridge" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/PolygonBridge.svg" alt="Polygon Bridge" />
                    <EcoName>Polygon Bridge</EcoName>
                  </EcoItem>
                </EcoList>
              </Typography.Paragraph>
            </TabPane>
            <TabPane key='6' title='Conflux'>
              <Typography.Paragraph>
                <EcoList>
                  <EcoItem href="https://app.swappi.io/" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/Swappi.svg" alt="Swappi" />
                    <EcoName>Swappi</EcoName>
                  </EcoItem>
                  <EcoItem href="https://cbridge.celer.network/1030/42161/DF" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/cBridge.svg" alt="cBridge" />
                    <EcoName>cBridge</EcoName>
                  </EcoItem>
                </EcoList>
              </Typography.Paragraph>
            </TabPane>
          </Tabs>
          <ExTitle>CEX<a name="a"></a></ExTitle>
          <EcoList>
            <EcoItem href="https://www.binance.com/en/trade/DF_USDT" target='_blank' rel="noreferrer">
              <img src="/images/Exchanges/Binance.svg" alt="Binance" />
              <EcoName>Binance</EcoName>
            </EcoItem>
            <EcoItem href="https://bingx.com/en-us/spot/DFUSDT/" target='_blank' rel="noreferrer">
              <img src="/images/Exchanges/BingX.svg" alt="BingX" />
              <EcoName>BingX</EcoName>
            </EcoItem>
            <EcoItem href="https://www.bitrue.com/trade/df_usdt" target='_blank' rel="noreferrer">
              <img src="/images/Exchanges/Bitrue.svg" alt="Bitrue" />
              <EcoName>Bitrue</EcoName>
            </EcoItem>
            <EcoItem href="https://www.gate.io/trade/DF_USDT" target='_blank' rel="noreferrer">
              <img src="/images/Exchanges/Gate.io.svg" alt="Gate.io" />
              <EcoName>Gate.io</EcoName>
            </EcoItem>
            <EcoItem href="https://www.htx.com/trade/df_usdt" target='_blank' rel="noreferrer">
              <img src="/images/Exchanges/HTX.svg" alt="HTX" />
              <EcoName>HTX</EcoName>
            </EcoItem>
            <EcoItem href="https://latoken.com/exchange/USDT_DF" target='_blank' rel="noreferrer">
              <img src="/images/Exchanges/Latoken.svg" alt="Latoken" />
              <EcoName>Latoken</EcoName>
            </EcoItem>
            <EcoItem href="https://www.mexc.com/exchange/DF_USDT" target='_blank' rel="noreferrer">
              <img src="/images/Exchanges/MEXC.svg" alt="MEXC" />
              <EcoName>MEXC</EcoName>
            </EcoItem>
            <EcoItem href="https://nominex.io/markets/DF/USDT" target='_blank' rel="noreferrer">
              <img src="/images/Exchanges/Nominex.svg" alt="Nominex" />
              <EcoName>Nominex</EcoName>
            </EcoItem>
            <EcoItem href="https://www.tokocrypto.com/trade/DF_USDT" target='_blank' rel="noreferrer">
              <img src="/images/Exchanges/TokoCrypto.svg" alt="TokoCrypto" />
              <EcoName>TokoCrypto</EcoName>
            </EcoItem>
            <EcoItem href="https://whitebit.com/trade/DF-USDT" target='_blank' rel="noreferrer">
              <img src="/images/Exchanges/WhiteBIT.svg" alt="WhiteBIT" />
              <EcoName>WhiteBIT</EcoName>
            </EcoItem>
          </EcoList>
        </Ecosystem>
        <Footer>
          <FooterTop>
            <Navlist>
              <NavItem>
                <Navdl>Governance</Navdl>
                <Navdt><a href="https://snapshot.org/#/dforcenet.eth" target='_blank' rel="noreferrer">Snapshot</a></Navdt>
                <Navdt><a href="https://forum.dforce.network/" target='_blank' rel="noreferrer">Forum</a></Navdt>
                <Navdt><a href="https://gov.dforce.network/#/StakeDF" target='_blank' rel="noreferrer">Staking</a></Navdt>
                <Navdt><a><Link to="/AcquireDF">Acquire DF</Link></a></Navdt>
              </NavItem>
              {/* <NavItem>
                <Navdl>Legacy dApps</Navdl>
                <Navdt><a href="#" target='_blank' rel="noreferrer">Yield</a></Navdt>
                <Navdt><a href="#" target='_blank' rel="noreferrer">USDx</a></Navdt>
              </NavItem> */}
              <NavItem>
                <Navdl>Products</Navdl>
                <Navdt><a href='https://usx.finance/' target='_blank' rel="noreferrer">USX Finance</a></Navdt>
                <Navdt><a href="https://unitus.finance" target='_blank' rel="noreferrer">Unitus Finance</a></Navdt>
                <Navdt><a href="https://bridge.dforce.network" target='_blank' rel="noreferrer">Bridge</a></Navdt>
                <Navdt><a href="https://app.dforce.network" target='_blank' rel="noreferrer">Legacy dApp</a></Navdt>
              </NavItem>
              <NavItem>
                <Navdl>About</Navdl>
                <Navdt><a href="https://docs.dforce.network/" target='_blank' rel="noreferrer">Docs</a></Navdt>
                <Navdt><a href="https://medium.com/dforcenet" target='_blank' rel="noreferrer">Blog</a></Navdt>
                <Navdt><a href="https://github.com/dforce-network/recruitment" target='_blank' rel="noreferrer">Careers</a></Navdt>
              </NavItem>
              <NavItem>
                <Navdl>Contact</Navdl>
                <Navdt><a href="mailto:contact@dforce.network" target="_blank" rel="noreferrer">contact@dforce.network</a></Navdt>
              </NavItem>
            </Navlist>
            <IconList>
              <Icon href="https://twitter.com/dForcenet" target='_blank' rel="noreferrer"><img src="/images/link/Twitter.svg" alt="Twitter" /></Icon>
              <Icon href="https://t.me/dforcenet" target='_blank' rel="noreferrer"><img src="/images/link/Telegram.svg" alt="Telegram" /></Icon>
              {/* <Icon href="#" target='_blank' rel="noreferrer"><img src="/images/link/Medium.svg" alt="Medium" /></Icon> */}
              <Icon href="https://discord.com/invite/c2PC8SM" target='_blank' rel="noreferrer"><img src="/images/link/Discord.svg" alt="Discord" /></Icon>
              <Icon href="https://github.com/dforce-network" target='_blank' rel="noreferrer"><img src="/images/link/Github.svg" alt="Github" /></Icon>
            </IconList>
          </FooterTop>
          <FooteBottom>
            <a>
              <Log src="/images/logo.svg" />
            </a>
            {/* <Contact href="mailto:contact@dforce.network" target="_blank" rel="noreferrer">contact@dforce.network</Contact> */}
          </FooteBottom>
        </Footer>
      </Container>
      <Mmenu className={menu ? 'show' : ''} onClick={showMenu}>
        <Mnav>
          <CloseWarp>
            <a>Products</a>
            <img src="/images/Close.svg" alt="Close" width="18" onClick={() => { setMenu(false) }} />
          </CloseWarp>
          <ChildList>
            <a href='https://usx.finance/' target='_blank' rel="noreferrer">USX Finance</a>
            <a href="https://unitus.finance" target='_blank' rel="noreferrer">Unitus Finance</a>
            <a href="https://bridge.dforce.network" target='_blank' rel="noreferrer">Bridge</a>
            <a href="https://app.dforce.network" target='_blank' rel="noreferrer">Legacy dApp</a>
          </ChildList>
          <a>Community</a>
          <ChildList>
            <a href="https://twitter.com/dForcenet" target="_blank" rel="noopener noreferrer">X</a>
            <a href="https://t.me/dforcenet" target="_blank" rel="noopener noreferrer">Telegram</a>
            <a href="https://discord.com/invite/c2PC8SM" target="_blank" rel="noopener noreferrer">Discord</a>
            <a href="https://github.com/dforce-network" target="_blank" rel="noopener noreferrer">GitHub</a>
          </ChildList>
          <a>Governance</a>
          <ChildList>
            <a href="https://snapshot.org/#/dforcenet.eth" target="_blank" rel="noopener noreferrer">Snapshot</a>
            <a href="https://forum.dforce.network/" target="_blank" rel="noopener noreferrer">Forum</a>
            <a href="https://gov.dforce.network/#/StakeDF" target="_blank" rel="noopener noreferrer">Staking</a>
            <a><Link to="/AcquireDF">Acquire DF</Link></a>
          </ChildList>
          <a>About</a>
          <ChildList>
            <a href="https://docs.dforce.network/" target="_blank" rel="noopener noreferrer">Docs</a>
            <a href="https://medium.com/dforcenet" target="_blank" rel="noopener noreferrer">Blog</a>
            <a href="https://github.com/dforce-network/recruitment" target="_blank" rel="noopener noreferrer">Careers</a>
            <a href="mailto:contact@dforce.network" target="_blank" rel="noopener noreferrer">contact</a>
          </ChildList>
          {/* <a style={{ cursor: 'pointer' }} onClick={() => scrollToAnchor()}>Buy DF</a> */}
        </Mnav>
      </Mmenu>
    </>
  );
};
export default AcquireDF;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  background: linear-gradient(0deg, #F6F9FF 20.86%, #D8E9FF 100%);
  &::before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 491px;
    height: 804px;
    background-image: url("/images/leftBg.svg");
    background-size: cover;
    z-index: 0;
  }
  &::after{
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 310px;
    height: 201px;
    background-image: url("/images/rightTopBg.svg");
    background-size: cover;
    z-index: 0;
  }
  @media (max-width: 1360px) {
    &::before{
    width: 250px;
    height: 402px;
    background-size: cover;
  }
  &::after{
    width: 155px;
    height: 100px;
    background-size: cover;
  }
  }
`;
const HeaderWarp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  
`
const Menu = styled.div`
display: none;
@media (max-width: 1360px) {
  display: block;
  width: 26px;
  height: 26px;
  margin-left: 8px;
  z-index: 2;
  img {
    width: 26px;
    height: 26px;
  }
}
`;
const Mnav = styled.nav`
  display: none;
  @media (max-width: 1360px) {
    display: flex;
    flex-direction: column;
    width: calc( 100% - 24px);
    position: absolute;
    right: 12px;
    top: 38px;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid rgba(39, 164, 255, 0.20);
    background: #F8FAFF;
    box-sizing: border-box;
    a {
      font-size: 12px;
      font-weight: 600;
      line-height: 36px;
    }
  }
`;
const CloseWarp = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ChildList = styled.div`
 display: flex;
 flex-direction: column;
  padding: 16px 12px;
  border-radius: 8px;
background: #FFF;
  a{
    font-size: 12px;
    font-weight: 500;
    line-height: 26px;
  }
;
`
const Mmenu = styled.section`
  display: none;
  @media (max-width: 1360px) {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    &.show {
      display: flex;
      flex-direction: column;
    }
  }
`;
const Header = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  z-index: 2;
  @media (max-width: 1360px) {
    background-position: center;
  }
`;
const TopBar = styled.header`
  width: 1360px;
  height: 60px;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1360px) {
    width: 100%;
    padding: 0 12px;
    height: 38px;
    line-height: 38px;
  }
  a{
    display: flex;
  }
`;
const Log = styled.img`
  width: 110px;
  height: 24px;
  @media (max-width: 1360px) {
    width: 83px;
    height: 22px;
  }
`;
const ChangeLanguage = styled.li`
  cursor: pointer;
  /* margin-right: 24px; */
  position: relative;
  background: transparent;
  border: none;
  font-size: 16px;
  .showList {
    display: none;
  }
  &:hover {
    .showList {
      display: flex;
    }
  }
`
const Changelist = styled.ul`
  &.hide {
    display: none;
  }
  width: max-content;
  position: absolute;
  left: 50%;
  top: 65px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: #FFF;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  a {
    width: 100%;
    line-height: 36px;
    font-weight: 400;
  }
`;
const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  z-index: 2;
  li {
    padding:8px 16px; 
  }
  @media (max-width: 1360px) {
    display: none;
  }
`;
const ExTitle = styled.p`
font-size: 48px;
font-weight: 600;
line-height: 48px;
@media (max-width: 1360px) {
  font-size: 20px;
  line-height: 20px;
}
`
const Ecosystem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 1360px;
  padding-top: 60px;
  @media (max-width: 1360px) {
    width: 100%;
    flex-direction: column;
    gap: 20px;
    padding:30px 16px 0;
    margin-bottom: 40px;
  }
`
const EcoList = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  width: 100%;
  gap: 32px;
  @media (max-width: 1360px) {
    gap: 12px;
  }
`
const EcoItem = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 200px;
  height: 200px;
  padding: 40px 0;
  background-color: #fff;
  border-radius: 12px;
  margin: 0!important;
  cursor: pointer;
  img{
      width: 64px;
      height: 64px;
    }
  @media (max-width: 1360px) {
    gap: 20px;
    width: calc( (100% - 36px) / 3 );
    height: auto;
    padding: 25px;
    border-radius: 6px;
    img{
      width: 28px;
      height: 28px;
    }
  }
`
const EcoName = styled.div`
font-size: 16px;
font-weight: 400;
line-height: 16px;
@media (max-width: 1360px) {
  font-size: 9px;
  line-height: 9px;
}
`
const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 1360px;
  margin-top: 160px;
  @media (max-width: 1360px) {
    width: 100%;
    flex-direction: column;
    padding:0 16px;
    gap: 5px;
    margin-top: 0;
  }
`
const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1360px) {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }
`
const Navlist = styled.ul`
  display: flex;
  justify-content: space-between;
  gap: 120px;
  @media (max-width: 1360px) {
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 16px;
  }
`
const NavItem = styled.li`
  display: flex;
  flex-direction: column;
  @media (max-width: 1360px) {
    width: 30%;
  }
`
const Navdl = styled.dl`
font-size: 16px;
font-weight: 600;
line-height: 16px;
margin-bottom: 16px;
@media (max-width: 1360px) {
  font-size: 10px;
  line-height: 10px;
}
`
const Navdt = styled.dt`
font-size: 14px;
font-weight: 400;
line-height: 32px;
a{
  color: rgba(121, 126, 132, 1);
}
@media (max-width: 1360px) {
  font-size: 10px;
  line-height: 18px;
}
`
const IconList = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  gap: 16px;
  @media (max-width: 1360px) {
    gap: 8px;
    align-self: flex-start;
  }
`
const Icon = styled.a`
  img{
    display: inline-block;
  width: 36px;
  height: 36px;
  }
  @media (max-width: 1360px) {
    img{
      width: 24px;
      height: 24px;
    }
  }
`
const FooteBottom = styled.div`
  display: flex;
  justify-content: space-between;
padding: 24px 0px;
border-top: 1px solid rgba(0, 0, 0, 0.08);
@media (max-width: 1360px) {
  padding: 20px 0;
}
`
