import * as React from 'react';
import { useState } from 'react';
import {
  Routes, Route, Navigate
} from "react-router-dom";
import styled from 'styled-components';
import { createIntl, createIntlCache, RawIntlProvider } from "react-intl";
import en_US from "./i18n/en_US.js";
import zh_CN from "./i18n/zh_CN.js";
import Home from './components/Home.jsx'
import AcquireDF from './components/AcquireDF.jsx'

// 中英翻译
const messages = {
  en: en_US,
  cn: zh_CN,
};
const local_lauguage = window.localStorage.getItem("dforce-language");
const initialLocale = local_lauguage || "en";
const cache = createIntlCache();

let intl = createIntl(
  {
    locale: initialLocale,
    defaultLocale: "en",
    messages: messages[initialLocale],
  },
  cache
);
const App = () => {
  // 中英翻译
  const [locale, setLocale] = useState(initialLocale);

  const changeLanguage = newLocale => {
    intl = createIntl(
      { locale: newLocale, defaultLocale: "en", messages: messages[newLocale] },
      cache
    );
    console.log(newLocale);
    window.localStorage.setItem("pos-language", newLocale);
    document.documentElement.lang = newLocale;
    setLocale(newLocale);
  };
  return (
    <RawIntlProvider value={intl}>
      <Routes>
        <Route path="/" element={<Home changeLanguage={changeLanguage} locale={locale} />} />
        <Route path="/AcquireDF" element={<AcquireDF />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </RawIntlProvider>
  )
};

export default App;