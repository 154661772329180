import React, { useState, useEffect, useRef } from 'react';
import {
  Link
} from "react-router-dom";
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Tabs, Typography, Statistic } from '@arco-design/web-react';
import { _formatNumberNotCarry,bnToDec } from '../utils'
// import "./ca"
import "./1"
const TabPane = Tabs.TabPane;
const Home = ({ changeLanguage, locale }) => {
  const intl = useIntl();
  const fmt = intl.formatMessage;
  const countRef = useRef(null);
  const countRef2 = useRef(null);
  const countRef3 = useRef(null);
  const countRef4 = useRef(null);
  const [TotalVolumes, setTotalVolumes] = useState(undefined)
  const [TotalTransactions, setTotalTransactions] = useState(undefined)
  const [TotalUsers, setTotalUsers] = useState(undefined)
  const [TotalAssets, setTotalAssets] = useState(undefined)
  const [data, setData] = useState(undefined)
  const [menu, setMenu] = useState(false)
  const [showDetail, setShowDetail] = useState(0);
  const showDetailHandler = (e) => {
    // 点击tr行 显示 Detail 组件, 点击Detail组件禁止冒泡
    console.log(e.target.dataset.id)
    if (!(e.target.dataset.id)) return;
    setShowDetail(Number(e.target.dataset.id))
  }
  const [activeTab, setActiveTab] = useState('1');
  const showMenu = () => {
    setMenu(!menu)
  }
  const Fetch = () => {
    try {
      fetch("https://dforce.network/dashboard/website/info")
        .then(response => response.json())
        .catch(error => console.error('Error:', error))
        .then(response => {
          if (response?.status === 'OK') {
            setData(response?.data)
            setTotalVolumes(bnToDec(response?.data?.TotalVolumes,18))
            setTotalTransactions(response?.data?.TotalTransactions)
            setTotalUsers(response?.data?.TotalUsers)
            setTotalAssets(bnToDec(response?.data?.TotalAssets,18))
          } else {
            setData(undefined)
            setTotalVolumes(undefined)
            setTotalTransactions(undefined)
            setTotalUsers(undefined)
            setTotalAssets(undefined)
          }
        });
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    Fetch()
  }, [])
  useEffect(() => {
    if (!data) return;
    let _TotalVolumes = Number(bnToDec(data?.TotalVolumes,18))
    let _TotalTransactions = Number(data?.TotalTransactions)
    let _TotalUsers = Number(data?.TotalUsers)
    let _TotalAssets = Number(bnToDec(data?.TotalAssets,18))
    window.TotalVolumes = setInterval(() => {
      // const changeVal = Math.floor(Math.random() * 256)
      _TotalVolumes = _TotalVolumes + 1
      setTotalVolumes(_TotalVolumes)
    }, 200);
    window.TotalTransactions = setInterval(() => {
      // const changeVal = Math.floor(Math.random() * 6)
      _TotalTransactions = _TotalTransactions + 1
      setTotalTransactions(_TotalTransactions)
    }, 5000);
    window.TotalUsers = setInterval(() => {
      // const changeVal = Math.floor(Math.random() * 3)
      _TotalUsers = _TotalUsers + 1
      setTotalUsers(_TotalUsers)
    }, 1000*10);
    window.TotalAssets = setInterval(() => {
      // const changeVal = Math.floor(Math.random() * 192)
      _TotalAssets = _TotalAssets + 1
      setTotalAssets(_TotalAssets)
    }, 200);
    return () => {
      clearInterval(window.TotalVolumes)
      clearInterval(window.TotalTransactions)
      clearInterval(window.TotalUsers)
      clearInterval(window.TotalAssets)
    }
  }, [data])
  const scrollToAnchor = () => {
    const anchorName = "BuyDF"
    // 找到锚点
    let anchorElement = document.getElementById(anchorName);
    // 如果对应id的锚点存在，就跳转到锚点
    setActiveTab('3')
    if (anchorElement) { anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
  }
  return (
    <>
      <Container>
        <HeaderWarp>
        <Header>
          <TopBar>
            <a href="https://www.dforce.network/" rel="noopener noreferrer">
              <Log src="/images/logo.svg" />
            </a>
            <Nav>
              <ChangeLanguage
              >
                <span>Products</span>
                <Changelist className={'showList'}>
                  <a href='https://usx.finance/' target='_blank' rel="noreferrer">USX Finance</a>
                  <a href="https://unitus.finance" target='_blank' rel="noreferrer">Unitus Finance</a>
                  <a href="https://bridge.dforce.network" target='_blank' rel="noreferrer">Bridge</a>
                  <a href="https://app.dforce.network" target='_blank' rel="noreferrer">Legacy dApp</a>
                </Changelist>
              </ChangeLanguage>
              <ChangeLanguage
              >
                <span>Community</span>
                <Changelist className={'showList'}>
                  <a href="https://twitter.com/dForcenet" target="_blank" rel="noopener noreferrer">X</a>
                  <a href="https://t.me/dforcenet" target="_blank" rel="noopener noreferrer">Telegram</a>
                  <a href="https://discord.com/invite/c2PC8SM" target="_blank" rel="noopener noreferrer">Discord</a>
                  <a href="https://github.com/dforce-network" target="_blank" rel="noopener noreferrer">GitHub</a>
                </Changelist>
              </ChangeLanguage>
              {/* <ChangeLanguage
              >
                <span>Legacy dApps</span>
                <Changelist className={'showList'}>
                  <a href="#" target="_blank" rel="noopener noreferrer">Yield</a>
                  <a href="#" target="_blank" rel="noopener noreferrer">USDx</a>
                </Changelist>
              </ChangeLanguage> */}
              <ChangeLanguage
              >
                <span>Governance</span>
                <Changelist className={'showList'}>
                  <a href="https://snapshot.org/#/dforcenet.eth" target="_blank" rel="noopener noreferrer">Snapshot</a>
                  <a href="https://forum.dforce.network/" target="_blank" rel="noopener noreferrer">Forum</a>
                  <a href="https://gov.dforce.network/#/StakeDF" target="_blank" rel="noopener noreferrer">Staking</a>
                  <a><Link to="/AcquireDF">Acquire DF</Link></a>
                </Changelist>
              </ChangeLanguage>
              <ChangeLanguage
              >
                <span>About</span>
                <Changelist className={'showList'}>
                  <a href="https://docs.dforce.network/" target="_blank" rel="noopener noreferrer">Docs</a>
                  <a href="https://medium.com/dforcenet" target="_blank" rel="noopener noreferrer">Blog</a>
                  <a href="https://github.com/dforce-network/recruitment" target="_blank" rel="noopener noreferrer">Careers</a>
                  <a href="mailto:contact@dforce.network" target="_blank" rel="noopener noreferrer">contact</a>
                </Changelist>
              </ChangeLanguage>
              {/* <li>
                <a style={{ cursor: 'pointer' }} onClick={() => scrollToAnchor()}>Buy DF</a>
              </li> */}
              {/* <ChangeLanguage onClick={showList}>
                <img
                  src={locale === 'en' ? '/images/EN.svg' : '/images/CN.svg'}
                />
                <span>{locale === 'en' ? 'English' : '简体中文'}</span>
                <b></b>
                <Changelist ref={ref} className={open ? '' : 'hide'}>
                  <li onClick={() => changeLanguage('en')}>
                    <img src="/images/EN.svg" />
                    <span>English</span>
                  </li>
                  <li onClick={() => changeLanguage('cn')}>
                    <img src="/images/CN.svg" />
                    <span>简体中文</span>
                  </li>
                </Changelist>
              </ChangeLanguage> */}
            </Nav>
            <Menu onClick={showMenu}>
              <img src="/images/m-menu.svg" alt="" />
            </Menu>
          </TopBar>
        </Header>
        <Slogon>
          <SlogonLeft>
            <p>Permissionless </p>
            <p className='blue'>Liquidity Network </p>
            <p>Built for Web3 </p>
            <SlogonTxt>
            Create a permissionless liquidity infrastructure for Web3 dApps and build the integrated liquidity layer. 
            </SlogonTxt>
          </SlogonLeft>
          <SlogonRight id="sr">
            {/* <canvas id="c" height="560" width="508"></canvas> */}
            <div class="container">
              <div class="dajianshi">
                <div class="eye"></div>
                <div class="circle-1"></div>
                <div class="circle-2"></div>
                <div class="circle-3"></div>
                <div class="circle-4"></div>
                <div class="circle-5"></div>
                <div class="circle-6"></div>
                <div class="circle-7"></div>
                <div class="circle-8"></div>
                <div class="circle-9"></div>
                <div class="circle-10"></div>
                <div class="circle-11"></div>
                <div class="circle-12"></div>
                <div class="circle-13"></div>
                <div class="circle-14"></div>
                <div class="glitch"></div>
                <div class="fragment-1"></div>
                <div class="fragment-2"></div>
                <div class="fragment-3"></div>
                <svg width="190" height="190" viewBox="0 0 190 190" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <defs>
                    <clipPath id='bagel1'>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M95 190C147.467 190 190 147.467 190 95C190 42.533 147.467 0 95 0C42.533 0 0 42.533 0 95C0 147.467 42.533 190 95 190ZM95 120C108.807 120 120 108.807 120 95C120 81.1929 108.807 70 95 70C81.1929 70 70 81.1929 70 95C70 108.807 81.1929 120 95 120Z" />
                    </clipPath>
                    <clipPath id='bagel2'>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M71 142C110.212 142 142 110.212 142 71C142 31.7878 110.212 0 71 0C31.7878 0 0 31.7878 0 71C0 110.212 31.7878 142 71 142ZM71 139C108.555 139 139 108.555 139 71C139 33.4446 108.555 3 71 3C33.4446 3 3 33.4446 3 71C3 108.555 33.4446 139 71 139Z" />
                    </clipPath>
                    <clipPath id='bagel3'>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M60 120C93.1372 120 120 93.1372 120 60C120 26.8628 93.1372 0 60 0C26.8628 0 0 26.8628 0 60C0 93.1372 26.8628 120 60 120ZM60 115C90.3757 115 115 90.3757 115 60C115 29.6243 90.3757 5 60 5C29.6243 5 5 29.6243 5 60C5 90.3757 29.6243 115 60 115Z" />
                    </clipPath>
                    <clipPath id='bagel4'>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M38 76C58.9868 76 76 58.9868 76 38C76 17.0132 58.9868 0 38 0C17.0132 0 0 17.0132 0 38C0 58.9868 17.0132 76 38 76ZM38 72C56.7777 72 72 56.7776 72 38C72 19.2224 56.7777 4 38 4C19.2223 4 4 19.2224 4 38C4 56.7776 19.2223 72 38 72Z" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </SlogonRight>
        </Slogon>
        </HeaderWarp>
        <DataList>
          <DataItem>
            <img src="/images/TotalTransactionVolume.svg" alt="" />
            {
              TotalVolumes ?
                <Statistic
                  className="countDown"
                  ref={countRef}
                  countDuration={200}
                  value={TotalVolumes}
                  groupSeparator
                  countUp
                />
                : <DataInit>...</DataInit>
            }
            <Text>Total Volumes($)</Text>
          </DataItem>
          <DataItem>
            <img src="/images/TotalTransaction.svg" alt="" />
            {/* <Value>{`$${_formatNumberNotCarry(bnToDec('18939192192323030023901', 18))}`}</Value> */}
            {
              TotalTransactions ?
                <Statistic
                  className="countDown"
                  ref={countRef2}
                  countDuration={400}
                  value={TotalTransactions}
                  groupSeparator
                  countUp
                />
                : <DataInit>...</DataInit>
            }
            <Text>Total Transactions</Text>
          </DataItem>
          <DataItem>
            <img src="/images/TotalUsers.svg" alt="" />
            {/* <Value>{`$${_formatNumberNotCarry(bnToDec('18939192192323030023901', 18), '', 0, 0)}`}</Value> */}
            {
              TotalUsers ?
                <Statistic
                  className="countDown"
                  ref={countRef3}
                  countDuration={400}
                  value={TotalUsers}
                  groupSeparator
                  countUp
                />
                : <DataInit>...</DataInit>
            }
            <Text>Total Users</Text>
          </DataItem>
          <DataItem>
            <img src="/images/TotalAssets.svg" alt="" />
            {/* <Value>{`$${_formatNumberNotCarry(bnToDec('18939192192323030023901', 18))}`}</Value> */}
            {
              TotalAssets ?
                <Statistic
                  className="countDown"
                  ref={countRef4}
                  countDuration={200}
                  value={TotalAssets}
                  groupSeparator
                  countUp
                />
                : <DataInit>...</DataInit>
            }
            <Text>Total Assets($)</Text>
          </DataItem>
        </DataList>
        <Exploring>
          <ExTitle>Exploring dForce</ExTitle>
          <ExInfo>
            <ExPara>
            dForce has developed a comprehensive suite of web3 infrastructure protocols, including standardized decentralized stablecoins, general money markets, yield tokens, RWA tokens, and more. We also are committed to developing protocols that can significantly enhance liquidity in the Bitcoin L2, DePIN, and decentralized AI ecosystems.
            </ExPara>
            {/* <ExPara>In addition to DeFi and liquidity-oriented protocols, we are committed to developing protocols that can significantly enhance liquidity in the Bitcoin L2, DePIN, and decentralized AI ecosystems.</ExPara> */}
          </ExInfo>
          <ExContent>
              <Detail>
                <DetailPic src="/images/1.svg" alt="" />
                <DetailInfo>
                  <DetailTitle>Unified Money Market</DetailTitle>
                  <DetailPara>General money market protocol that offers a wide range of customization options, liquidity pooling, and risk segregation while maximizing capital efficiency.</DetailPara>
                </DetailInfo>
              </Detail>
              <Detail>
                <DetailPic src="/images/2.svg" alt="" />
                <DetailInfo>
                  <DetailTitle>Omni Stablecoin Infrastructure</DetailTitle>
                  <DetailPara>Building an interoperable, permissionless and hybrid stablecoin infrastructure to facilitate highly efficient cross-chain capabilities.</DetailPara>
                </DetailInfo>
              </Detail>
              <Detail>
                <DetailPic src="/images/3.svg" alt="" />
                <DetailInfo>
                  <DetailTitle>Yield Assets Standard</DetailTitle>
                  <DetailPara>Providing scalable, risk-adjusted yield assets with high composability.</DetailPara>
                </DetailInfo>
              </Detail>
              <Detail>
                <DetailPic src="/images/4.svg" alt="" />
                <DetailInfo>
                  <DetailTitle>RWA Tokenization Protocol</DetailTitle>
                  <DetailPara>Offering a generic, composable, and scalable tokenized RWA assets solution for DeFi integration.</DetailPara>
                </DetailInfo>
              </Detail>
              <Detail>
                <DetailPic src="/images/5.svg" alt="" />
                <DetailInfo>
                  <DetailTitle>AI-driven Automation for DeFi</DetailTitle>
                  <DetailPara>Implementing AI agents to facilitate intent-based trading aggregation, liquidity pooling, interest rate policy setting, and trading automation.</DetailPara>
                </DetailInfo>
              </Detail>
              <Detail>
                <DetailPic src="/images/6.svg" alt="" />
                <DetailInfo>
                  <DetailTitle>Interoperable LSD</DetailTitle>
                  <DetailPara>Develop LSD protocols that seamlessly operate across different blockchains, promoting interoperability and accessibility within DeFi ecosystem.</DetailPara>
                </DetailInfo>
              </Detail>
          </ExContent>
        </Exploring>
        <FeaturedWarp>
          <Featured>
            <FeaturedHeader>
              <FHtitle>Featured dApps</FHtitle>
              <FHPara>dForce is building a permissionless liquidity infrastructure, featuring ecosystem projects that operate within distinct verticals yet are harmoniously unified under an integrated protocol stack.</FHPara>
            </FeaturedHeader>
            <FeaturedList>
              <FeaturedItem>
                <ItemHeader>
                  <img src="/images/UTS.svg" alt="UTS" />
                  <AssetsTag href="https://unitus.finance" target='_blank' rel="noreferrer">Omni Money Market</AssetsTag>
                </ItemHeader>
                <ItemTitle>Unitus Finance</ItemTitle>
                <ItemPara>Unitus is an multi-chain lending protocol that enhances capital efficiency and flexibility, offers tailored risk management and features sustainable tokenomics to align interests between the protocol and its stake holders.</ItemPara>
              </FeaturedItem>
              <FeaturedItem>
                <ItemHeader>
                  <img src="/images/USX.svg" alt="USX" />
                  <AssetsTag href="https://usx.finance" target='_blank' rel="noreferrer">Decentralized Stablecoin</AssetsTag>
                </ItemHeader>
                <ItemTitle>USX Finance</ItemTitle>
                <ItemPara>USX is an omni decentralized stablecoin, scalable and vertically-integrated across all layers.
                </ItemPara>
              </FeaturedItem>
            </FeaturedList>
          </Featured>
        </FeaturedWarp>
        <Ecosystem id="BuyDF">
          <ExTitle>Exploring the ecosystem<a name="a"></a></ExTitle>
          <Tabs activeTab={activeTab} onChange={setActiveTab} animation>
            <TabPane key='1' title='Asset'>
              <Typography.Paragraph>
                <EcoList>
                  <EcoItem className="nocurson">
                    <img src="/images/Asset/ARB.svg" alt="ARB" />
                    <EcoName>ARB</EcoName>
                  </EcoItem>
                  <EcoItem className="nocurson">
                    <img src="/images/Asset/BNB.svg" alt="BNB" />
                    <EcoName>BNB</EcoName>
                  </EcoItem>
                  <EcoItem className="nocurson">
                    <img src="/images/Asset/DF.svg" alt="DF" />
                    <EcoName>DF</EcoName>
                  </EcoItem>
                  <EcoItem className="nocurson">
                    <img src="/images/Asset/DAI.svg" alt="DAI" />
                    <EcoName>DAI</EcoName>
                  </EcoItem>
                  <EcoItem className="nocurson">
                    <img src="/images/Asset/ETH.svg" alt="ETH" />
                    <EcoName>ETH</EcoName>
                  </EcoItem>
                  <EcoItem className="nocurson">
                    <img src="/images/Asset/OP.svg" alt="OP" />
                    <EcoName>OP</EcoName>
                  </EcoItem>
                  <EcoItem className="nocurson">
                    <img src="/images/Asset/sDAI.svg" alt="sDAI" />
                    <EcoName>sDAI</EcoName>
                  </EcoItem>
                  <EcoItem className="nocurson">
                    <img src="/images/Asset/USDC.svg" alt="USDC" />
                    <EcoName>USDC</EcoName>
                  </EcoItem>
                  <EcoItem className="nocurson">
                    <img src="/images/Asset/USDT.svg" alt="USDT" />
                    <EcoName>USDT</EcoName>
                  </EcoItem>
                  <EcoItem className="nocurson">
                    <img src="/images/Asset/USX.svg" alt="USX" />
                    <EcoName>USX</EcoName>
                  </EcoItem>
                  <EcoItem className="nocurson">
                    <img src="/images/Asset/WBTC.svg" alt="WBTC" />
                    <EcoName>WBTC</EcoName>
                  </EcoItem>
                  <EcoItem className="nocurson">
                    <img src="/images/Asset/wstETH.svg" alt="wstETH" />
                    <EcoName>wstETH</EcoName>
                  </EcoItem>
                </EcoList>
              </Typography.Paragraph>
            </TabPane>
            <TabPane key='2' title='Audit'>
              <Typography.Paragraph>
                <EcoList>
                  <EcoItem  href="https://mixbytes.io/" target='_blank' rel="noreferrer">
                    <img src="/images/Audit/MixBytes.svg" alt="MixBytes" />
                    <EcoName>MixBytes</EcoName>
                  </EcoItem>
                  <EcoItem  href="https://www.trailofbits.com/" target='_blank' rel="noreferrer">
                    <img src="/images/Audit/TrailofBits.svg" alt="Trail of Bits" />
                    <EcoName>Trail of Bits</EcoName>
                  </EcoItem>
                  <EcoItem  href="https://consensys.io/diligence/" target='_blank' rel="noreferrer">
                    <img src="/images/Audit/ConsenSysDiligence.svg" alt="ConsenSys Diligence" />
                    <EcoName>ConsenSys Diligence</EcoName>
                  </EcoItem>
                  <EcoItem  href="https://www.certik.com/" target='_blank' rel="noreferrer">
                    <img src="/images/Audit/CertiK.svg" alt="CertiK" />
                    <EcoName>CertiK</EcoName>
                  </EcoItem>
                  <EcoItem  href="https://www.certora.com/" target='_blank' rel="noreferrer">
                    <img src="/images/Audit/Certora.svg" alt="Certora" />
                    <EcoName>Certora</EcoName>
                  </EcoItem>
                  <EcoItem  href="https://quantstamp.com/" target='_blank' rel="noreferrer">
                    <img src="/images/Audit/QuantStamp.svg" alt="QuantStamp" />
                    <EcoName>QuantStamp</EcoName>
                  </EcoItem>
                  <EcoItem  href="https://peckshield.com/" target='_blank' rel="noreferrer">
                    <img src="/images/Audit/PeckShield.svg" alt="PeckShield" />
                    <EcoName>PeckShield</EcoName>
                  </EcoItem>
                  <EcoItem  href="https://cn.slowmist.com/" target='_blank' rel="noreferrer">
                    <img src="/images/Audit/SlowMist.svg" alt="SlowMist" />
                    <EcoName>SlowMist</EcoName>
                  </EcoItem>
                  <EcoItem  href="https://immunefi.com/" target='_blank' rel="noreferrer">
                    <img src="/images/Audit/Immunefi.svg" alt="Immunefi" />
                    <EcoName>Immunefi</EcoName>
                  </EcoItem>
                </EcoList>
              </Typography.Paragraph>
            </TabPane>
            <TabPane key='3' title='Blockchain'>
              <Typography.Paragraph>
                <EcoList>
                  <EcoItem href="https://ethereum.org/" target='_blank' rel="noreferrer">
                      <img src="/images/Blockchain/Blockchain-Ethereum.svg" alt="Ethereum" />
                      <EcoName>Ethereum</EcoName>
                    </EcoItem>
                  <EcoItem href="https://arbitrum.io/" target='_blank' rel="noreferrer">
                    <img src="/images/Blockchain/Blockchain-arb.svg" alt="arb" />
                    <EcoName>Arbitrum</EcoName>
                  </EcoItem>
                  <EcoItem href="https://www.optimism.io/" target='_blank' rel="noreferrer">
                    <img src="/images/Blockchain/Blockchain-Optimism.svg" alt="Optimism" />
                    <EcoName>Optimism</EcoName>
                  </EcoItem>
                  <EcoItem href="https://www.base.org/" target='_blank' rel="noreferrer">
                    <img src="/images/Blockchain/Blockchain-base.svg" alt="base" />
                    <EcoName>Base</EcoName>
                  </EcoItem>
                  <EcoItem href="https://www.bnbchain.org/" target='_blank' rel="noreferrer">
                    <img src="/images/Blockchain/Blockchain-BSC.svg" alt="BSC" />
                    <EcoName>BSC</EcoName>
                  </EcoItem>
                  <EcoItem href="https://polygon.technology/" target='_blank' rel="noreferrer">
                    <img src="/images/Blockchain/Blockchain-Polygon.svg" alt="Polygon" />
                    <EcoName>Polygon</EcoName>
                  </EcoItem>
                  <EcoItem href="https://confluxnetwork.org/" target='_blank' rel="noreferrer">
                    <img src="/images/Blockchain/Blockchain-Conflux.svg" alt="Conflux" />
                    <EcoName>Conflux</EcoName>
                  </EcoItem>
                  <EcoItem href="https://www.avax.network/" target='_blank' rel="noreferrer">
                    <img src="/images/Blockchain/Blockchain-Avalanche.svg" alt="Avalanche" />
                    <EcoName>Avalanche</EcoName>
                  </EcoItem>
                  <EcoItem href="https://zksync.io/" target='_blank' rel="noreferrer">
                    <img src="/images/Blockchain/Blockchain-zkSync.svg" alt="zkSync" />
                    <EcoName>zkSync</EcoName>
                  </EcoItem>
                  <EcoItem href="https://www.kava.io/" target='_blank' rel="noreferrer">
                    <img src="/images/Blockchain/Blockchain-KAVA.svg" alt="KAVA" />
                    <EcoName>KAVA</EcoName>
                  </EcoItem>
                </EcoList>
              </Typography.Paragraph>
            </TabPane>
            <TabPane key='4' title='DEX'>
              <Typography.Paragraph>
                <EcoList>
                  <EcoItem href="https://velodrome.finance/" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/Velodrome.svg" alt="Velodrome" />
                    <EcoName>Velodrome</EcoName>
                  </EcoItem>
                  <EcoItem href="https://aerodrome.finance/" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/Aerodrome.svg" alt="Aerodrome" />
                    <EcoName>Aerodrome</EcoName>
                  </EcoItem>
                  <EcoItem href="https://balancer.fi/" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/Balancer.svg" alt="Balancer" />
                    <EcoName>Balancer</EcoName>
                  </EcoItem>
                  <EcoItem href="https://camelot.exchange/" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/Camelot.svg" alt="Camelot" />
                    <EcoName>Camelot</EcoName>
                  </EcoItem>
                  <EcoItem href="https://curve.fi/" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/Curve.svg" alt="Curve" />
                    <EcoName>Curve</EcoName>
                  </EcoItem>
                  <EcoItem href="https://dodoex.io/" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/DODO.svg" alt="DODO" />
                    <EcoName>DODO</EcoName>
                  </EcoItem>
                  <EcoItem href="https://uniswap.org/" target='_blank' rel="noreferrer">
                    <img src="/images/DEX/Uniswap.svg" alt="Uniswap" />
                    <EcoName>Uniswap</EcoName>
                  </EcoItem>
                  
                </EcoList>
              </Typography.Paragraph>
            </TabPane>
            <TabPane key='5' title='Exchanges'>
              <Typography.Paragraph>
                <EcoList>
                  <EcoItem href="https://binance.com/" target='_blank' rel="noreferrer">
                    <img src="/images/Exchanges/Binance.svg" alt="Binance" />
                    <EcoName>Binance</EcoName>
                  </EcoItem>
                  <EcoItem href="https://bingx.com/" target='_blank' rel="noreferrer">
                    <img src="/images/Exchanges/BingX.svg" alt="BingX" />
                    <EcoName>BingX</EcoName>
                  </EcoItem>
                  <EcoItem href="https://www.bitrue.com/" target='_blank' rel="noreferrer">
                    <img src="/images/Exchanges/Bitrue.svg" alt="Bitrue" />
                    <EcoName>Bitrue</EcoName>
                  </EcoItem>
                  <EcoItem href="http://gate.io/" target='_blank' rel="noreferrer">
                    <img src="/images/Exchanges/Gate.io.svg" alt="Gate.io" />
                    <EcoName>Gate.io</EcoName>
                  </EcoItem>
                  <EcoItem href="https://www.htx.com/" target='_blank' rel="noreferrer">
                    <img src="/images/Exchanges/HTX.svg" alt="HTX" />
                    <EcoName>HTX</EcoName>
                  </EcoItem>
                  <EcoItem href="https://latoken.com/" target='_blank' rel="noreferrer">
                    <img src="/images/Exchanges/Latoken.svg" alt="Latoken" />
                    <EcoName>Latoken</EcoName>
                  </EcoItem>
                  <EcoItem href="https://www.mexc.com/" target='_blank' rel="noreferrer">
                    <img src="/images/Exchanges/MEXC.svg" alt="MEXC" />
                    <EcoName>MEXC</EcoName>
                  </EcoItem>
                  <EcoItem href="https://nominex.io/" target='_blank' rel="noreferrer">
                    <img src="/images/Exchanges/Nominex.svg" alt="Nominex" />
                    <EcoName>Nominex</EcoName>
                  </EcoItem>
                  <EcoItem href="https://www.tokocrypto.com/" target='_blank' rel="noreferrer">
                    <img src="/images/Exchanges/TokoCrypto.svg" alt="TokoCrypto" />
                    <EcoName>TokoCrypto</EcoName>
                  </EcoItem>
                  <EcoItem href="https://whitebit.com/" target='_blank' rel="noreferrer">
                    <img src="/images/Exchanges/WhiteBIT.svg" alt="WhiteBIT" />
                    <EcoName>WhiteBIT</EcoName>
                  </EcoItem>
                </EcoList>
              </Typography.Paragraph>
            </TabPane>
            <TabPane key='6' title='Infra'>
              <Typography.Paragraph>
                <EcoList>
                  <EcoItem href="https://celer.network/" target='_blank' rel="noreferrer">
                    <img src="/images/Infra/Celer.svg" alt="Celer" />
                    <EcoName>Celer</EcoName>
                  </EcoItem>
                  <EcoItem href="https://galxe.com/" target='_blank' rel="noreferrer">
                    <img src="/images/Infra/Glaxe.svg" alt="Glaxe" />
                    <EcoName>Glaxe</EcoName>
                  </EcoItem>
                  <EcoItem href="https://layerzero.network/" target='_blank' rel="noreferrer">
                    <img src="/images/Infra/LayerZero.svg" alt="LayerZero" />
                    <EcoName>LayerZero</EcoName>
                  </EcoItem>
                  <EcoItem href="https://questn.com/" target='_blank' rel="noreferrer">
                    <img src="/images/Infra/QuestN.svg" alt="QuestN" />
                    <EcoName>QuestN</EcoName>
                  </EcoItem>
                  <EcoItem href="https://chain.link/" target='_blank' rel="noreferrer">
                    <img src="/images/Infra/ChainLink.svg" alt="ChainLink" />
                    <EcoName>ChainLink</EcoName>
                  </EcoItem>
                  <EcoItem href="https://pyth.network/" target='_blank' rel="noreferrer">
                    <img src="/images/Infra/Pyth.svg" alt="Pyth" />
                    <EcoName>Pyth</EcoName>
                  </EcoItem>
                </EcoList>
              </Typography.Paragraph>
            </TabPane>
            <TabPane key='7' title='Lending'>
              <Typography.Paragraph>
                <EcoList>
                  <EcoItem href="http://extrafi.io/" target='_blank' rel="noreferrer">
                    <img src="/images/Lending/ExtraFi.svg" alt="ExtraFi" />
                    <EcoName>ExtraFi</EcoName>
                  </EcoItem>
                  <EcoItem href="https://unitus.finance" target='_blank' rel="noreferrer">
                    <img src="/images/Lending/Unitus.svg" alt="Unitus" />
                    <EcoName>Unitus</EcoName>
                  </EcoItem>
                </EcoList>
              </Typography.Paragraph>
            </TabPane>
            <TabPane key='8' title='LSD'>
              <Typography.Paragraph>
                <EcoList>
                  <EcoItem href="https://aspidanet.com/" target='_blank' rel="noreferrer">
                    <img src="/images/LSD/Aspida.svg" alt="Aspida" />
                    <EcoName>Aspida</EcoName>
                  </EcoItem>
                  <EcoItem href="https://lido.finance/" target='_blank' rel="noreferrer">
                    <img src="/images/LSD/LidoFinance.svg" alt="Lido Finance" />
                    <EcoName>Lido Finance</EcoName>
                  </EcoItem>
                  <EcoItem href="https://rocketpool.net/" target='_blank' rel="noreferrer">
                    <img src="/images/LSD/RocketPool.svg" alt="RocketPool" />
                    <EcoName>RocketPool</EcoName>
                  </EcoItem>
                  <EcoItem href="https://ssv.network/" target='_blank' rel="noreferrer">
                    <img src="/images/LSD/SSV.svg" alt="SSV" />
                    <EcoName>SSV</EcoName>
                  </EcoItem>
                </EcoList>
              </Typography.Paragraph>
            </TabPane>
            <TabPane key='9' title='Wallet'>
              <Typography.Paragraph>
                <EcoList>
                  <EcoItem href="https://web3.bitget.com/" target='_blank' rel="noreferrer">
                    <img src="/images/Wallet/Bitget.svg" alt="Bitget" />
                    <EcoName>Bitget</EcoName>
                  </EcoItem>
                  <EcoItem href="https://bitpie.com/" target='_blank' rel="noreferrer">
                    <img src="/images/Wallet/Bitpie.svg" alt="Bitpie" />
                    <EcoName>Bitpie</EcoName>
                  </EcoItem>
                  <EcoItem href="https://www.coinbase.com/wallet" target='_blank' rel="noreferrer">
                    <img src="/images/Wallet/Coinbase.svg" alt="Coinbase" />
                    <EcoName>Coinbase</EcoName>
                  </EcoItem>
                  <EcoItem href="https://metamask.io/" target='_blank' rel="noreferrer">
                    <img src="/images/Wallet/Metamask.svg" alt="Metamask" />
                    <EcoName>Metamask</EcoName>
                  </EcoItem>
                  <EcoItem href="https://token.im/" target='_blank' rel="noreferrer">
                    <img src="/images/Wallet/imToken.svg" alt="imToken" />
                    <EcoName>imToken</EcoName>
                  </EcoItem>
                  <EcoItem href="https://mathwallet.org/" target='_blank' rel="noreferrer">
                    <img src="/images/Wallet/Math.svg" alt="Math" />
                    <EcoName>Math</EcoName>
                  </EcoItem>
                  <EcoItem href="https://www.okx.com/web3" target='_blank' rel="noreferrer">
                    <img src="/images/Wallet/OKX.svg" alt="OKX" />
                    <EcoName>OKX</EcoName>
                  </EcoItem>
                  <EcoItem href="https://www.onto.app/" target='_blank' rel="noreferrer">
                    <img src="/images/Wallet/ONTO.svg" alt="ONTO" />
                    <EcoName>ONTO</EcoName>
                  </EcoItem>
                  <EcoItem href="https://rabby.io/" target='_blank' rel="noreferrer">
                    <img src="/images/Wallet/Rabby.svg" alt="Rabby" />
                    <EcoName>Rabby</EcoName>
                  </EcoItem>
                  <EcoItem href="https://www.safepal.com/" target='_blank' rel="noreferrer">
                    <img src="/images/Wallet/SafePal.svg" alt="SafePal" />
                    <EcoName>SafePal</EcoName>
                  </EcoItem>
                  <EcoItem href="https://www.tokenpocket.pro/" target='_blank' rel="noreferrer">
                    <img src="/images/Wallet/TokenPocket.svg" alt="TokenPocket" />
                    <EcoName>TokenPocket</EcoName>
                  </EcoItem>
                  <EcoItem href="https://trustwallet.com/" target='_blank' rel="noreferrer">
                    <img src="/images/Wallet/TrustWallet.svg" alt="TrustWallet" />
                    <EcoName>TrustWallet</EcoName>
                  </EcoItem>
                </EcoList>
              </Typography.Paragraph>
            </TabPane>
            <TabPane key='10' title='Yield'>
              <Typography.Paragraph>
                <EcoList>
                  <EcoItem href="https://beefy.com/" target='_blank' rel="noreferrer">
                    <img src="/images/Yield/Beefy.svg" alt="Beefy" />
                    <EcoName>Beefy</EcoName>
                  </EcoItem>
                  <EcoItem href="https://app.acryptos.com/" target='_blank' rel="noreferrer">
                    <img src="/images/Yield/ACryptoS.svg" alt="ACryptoS" />
                    <EcoName>ACryptoS</EcoName>
                  </EcoItem>
                </EcoList>
              </Typography.Paragraph>
            </TabPane>
          </Tabs>
        </Ecosystem>
        <Governance>
          <FeaturedHeader>
            <FHtitle>Governance</FHtitle>
            <FHPara>dForce DAO ensures long-term success by making decentralization a guiding priority in governance.</FHPara>
          </FeaturedHeader>
          <GoverInfo>
            <GoverItem>
              <ItemLeft>
                <ItemTxt>
                  <GoverItemTitle>dForce Token</GoverItemTitle>
                  <GoverItemPara>dForce Token (DF) is the utility token that can be staked to govern dForce protocols and align interests across different parties within the dForce network, it is the ultimate value-capturer within the expansive dForce ecosystem.</GoverItemPara>
                </ItemTxt>
                <Arrow href="https://gov.dforce.network/#/StakeDF" target='_blank' rel="noreferrer">
                  <img src="/images/arrow.svg" alt="arrow" />
                </Arrow>
              </ItemLeft>
              <Banner src="/images/dForceToken.svg" alt="dForce Token" />
            </GoverItem>
            <GoverItem>
              <Banner className="BannerH5" src="/images/VoteEscrowedDF.png" alt="Vote Escrowed DF" />
              <ItemLeft>
                <ItemTxt>
                  <GoverItemTitle>Vote-Escrowed DF</GoverItemTitle>
                  <GoverItemPara>Vote-Escrowed DF (veDF) represents DF tokens that are locked in the voting escrow contract for a specified period. Users must lock DF tokens to gain access to governance and DF emission. veDF tokens are not transferable.</GoverItemPara>
                </ItemTxt>
                <Arrow href="https://gov.dforce.network/#/Lock" target='_blank' rel="noreferrer">
                  <img src="/images/arrow.svg" alt="arrow" />
                </Arrow>
              </ItemLeft>
            </GoverItem>
          </GoverInfo>
        </Governance>
        <Footer>
          <FooterTop>
            <Navlist>
              <NavItem>
                <Navdl>Governance</Navdl>
                <Navdt><a href="https://snapshot.org/#/dforcenet.eth" target='_blank' rel="noreferrer">Snapshot</a></Navdt>
                <Navdt><a href="https://forum.dforce.network/" target='_blank' rel="noreferrer">Forum</a></Navdt>
                <Navdt><a href="https://gov.dforce.network/#/StakeDF" target='_blank' rel="noreferrer">Staking</a></Navdt>
                <Navdt><a><Link to="/AcquireDF">Acquire DF</Link></a></Navdt>
              </NavItem>
              {/* <NavItem>
                <Navdl>Legacy dApps</Navdl>
                <Navdt><a href="#" target='_blank' rel="noreferrer">Yield</a></Navdt>
                <Navdt><a href="#" target='_blank' rel="noreferrer">USDx</a></Navdt>
              </NavItem> */}
              <NavItem>
                <Navdl>Products</Navdl>
                <Navdt><a href='https://usx.finance/' target='_blank' rel="noreferrer">USX Finance</a></Navdt>
                <Navdt><a href="https://unitus.finance" target='_blank' rel="noreferrer">Unitus Finance</a></Navdt>
                <Navdt><a href="https://bridge.dforce.network" target='_blank' rel="noreferrer">Bridge</a></Navdt>
                <Navdt><a href="https://app.dforce.network" target='_blank' rel="noreferrer">Legacy dApp</a></Navdt>
              </NavItem>
              <NavItem>
                <Navdl>About</Navdl>
                <Navdt><a href="https://docs.dforce.network/" target='_blank' rel="noreferrer">Docs</a></Navdt>
                <Navdt><a href="https://medium.com/dforcenet" target='_blank' rel="noreferrer">Blog</a></Navdt>
                <Navdt><a href="https://github.com/dforce-network/recruitment" target='_blank' rel="noreferrer">Careers</a></Navdt>
              </NavItem>
              <NavItem>
                <Navdl>Contact</Navdl>
                <Navdt><a href="mailto:contact@dforce.network" target="_blank" rel="noreferrer">contact@dforce.network</a></Navdt>
              </NavItem>
            </Navlist>
            <IconList>
              <Icon href="https://twitter.com/dForcenet" target='_blank' rel="noreferrer"><img src="/images/link/Twitter.svg" alt="Twitter" /></Icon>
              <Icon href="https://t.me/dforcenet" target='_blank' rel="noreferrer"><img src="/images/link/Telegram.svg" alt="Telegram" /></Icon>
              {/* <Icon href="#" target='_blank' rel="noreferrer"><img src="/images/link/Medium.svg" alt="Medium" /></Icon> */}
              <Icon href="https://discord.com/invite/c2PC8SM" target='_blank' rel="noreferrer"><img src="/images/link/Discord.svg" alt="Discord" /></Icon>
              <Icon href="https://github.com/dforce-network" target='_blank' rel="noreferrer"><img src="/images/link/Github.svg" alt="Github" /></Icon>
            </IconList>
          </FooterTop>
          <FooteBottom>
            <a>
              <Log src="/images/logo.svg" />
            </a>
            {/* <Contact href="mailto:contact@dforce.network" target="_blank" rel="noreferrer">contact@dforce.network</Contact> */}
          </FooteBottom>
        </Footer>
      </Container>
      <Mmenu className={menu ? 'show' : ''} onClick={showMenu}>
        <Mnav>
          <CloseWarp>
            <a>Products</a>
            <img src="/images/Close.svg" alt="Close" width="18" onClick={() => { setMenu(false) }} />
          </CloseWarp>
          <ChildList>
            <a href='https://usx.finance/' target='_blank' rel="noreferrer">USX Finance</a>
            <a href="https://unitus.finance" target='_blank' rel="noreferrer">Unitus Finance</a>
            <a href="https://bridge.dforce.network" target='_blank' rel="noreferrer">Bridge</a>
            <a href="https://app.dforce.network" target='_blank' rel="noreferrer">Legacy dApp</a>
          </ChildList>
          <a>Community</a>
          <ChildList>
            <a href="https://twitter.com/dForcenet" target="_blank" rel="noopener noreferrer">X</a>
            <a href="https://t.me/dforcenet" target="_blank" rel="noopener noreferrer">Telegram</a>
            <a href="https://discord.com/invite/c2PC8SM" target="_blank" rel="noopener noreferrer">Discord</a>
            <a href="https://github.com/dforce-network" target="_blank" rel="noopener noreferrer">GitHub</a>
          </ChildList>
          <a>Governance</a>
          <ChildList>
            <a href="https://snapshot.org/#/dforcenet.eth" target="_blank" rel="noopener noreferrer">Snapshot</a>
            <a href="https://forum.dforce.network/" target="_blank" rel="noopener noreferrer">Forum</a>
            <a href="https://gov.dforce.network/#/StakeDF" target="_blank" rel="noopener noreferrer">Staking</a>
            <a><Link to="/AcquireDF">Acquire DF</Link></a>
          </ChildList>
          <a>About</a>
          <ChildList>
            <a href="https://docs.dforce.network/" target="_blank" rel="noopener noreferrer">Docs</a>
            <a href="https://medium.com/dforcenet" target="_blank" rel="noopener noreferrer">Blog</a>
            <a href="https://github.com/dforce-network/recruitment" target="_blank" rel="noopener noreferrer">Careers</a>
            <a href="mailto:contact@dforce.network" target="_blank" rel="noopener noreferrer">contact</a>
          </ChildList>
          {/* <a style={{ cursor: 'pointer' }} onClick={() => scrollToAnchor()}>Buy DF</a> */}
        </Mnav>
      </Mmenu>
    </>
  );
};
export default Home;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  &::before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 491px;
    height: 804px;
    background-image: url("/images/leftBg.svg");
    background-size: cover;
    z-index: 0;
  }
  &::after{
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 310px;
    height: 201px;
    background-image: url("/images/rightTopBg.svg");
    background-size: cover;
    z-index: 0;
  }
  @media (max-width: 1360px) {
    &::before{
    width: 250px;
    height: 402px;
    background-size: cover;
  }
  &::after{
    width: 155px;
    height: 100px;
    background-size: cover;
  }
  }
`;
const HeaderWarp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: linear-gradient(0deg, #F6F9FF 20.86%, #D8E9FF 100%);
`
const Menu = styled.div`
display: none;
@media (max-width: 1360px) {
  display: block;
  width: 26px;
  height: 26px;
  margin-left: 8px;
  z-index: 2;
  img {
    width: 26px;
    height: 26px;
  }
}
`;
const Mnav = styled.nav`
  display: none;
  @media (max-width: 1360px) {
    display: flex;
    flex-direction: column;
    width: calc( 100% - 24px);
    position: absolute;
    right: 12px;
    top: 38px;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid rgba(39, 164, 255, 0.20);
    background: #F8FAFF;
    box-sizing: border-box;
    a {
      font-size: 12px;
      font-weight: 600;
      line-height: 36px;
    }
  }
`;
const CloseWarp = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ChildList = styled.div`
 display: flex;
 flex-direction: column;
  padding: 16px 12px;
  border-radius: 8px;
background: #FFF;
  a{
    font-size: 12px;
    font-weight: 500;
    line-height: 26px;
  }
;
`
const Mmenu = styled.section`
  display: none;
  @media (max-width: 1360px) {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    &.show {
      display: flex;
      flex-direction: column;
    }
  }
`;
const Header = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  z-index: 2;
  @media (max-width: 1360px) {
    background-position: center;
  }
`;
const TopBar = styled.header`
  width: 1360px;
  height: 60px;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1360px) {
    width: 100%;
    padding: 0 12px;
    height: 38px;
    line-height: 38px;
  }
  a{
    display: flex;
  }
`;
const Log = styled.img`
  width: 110px;
  height: 24px;
  @media (max-width: 1360px) {
    width: 83px;
    height: 22px;
  }
`;
const ChangeLanguage = styled.li`
  cursor: pointer;
  /* margin-right: 24px; */
  position: relative;
  background: transparent;
  border: none;
  font-size: 16px;
  .showList {
    display: none;
  }
  &:hover {
    .showList {
      display: flex;
    }
  }
`
const Changelist = styled.ul`
  &.hide {
    display: none;
  }
  width: max-content;
  position: absolute;
  left: 50%;
  top: 65px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: #FFF;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  a {
    width: 100%;
    line-height: 36px;
    font-weight: 400;
  }
`;
const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  z-index: 2;
  li {
    padding:8px 16px; 
  }
  @media (max-width: 1360px) {
    display: none;
  }
`;
const Slogon = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 1360px;
  padding: 80px 0;
  /* &::before{
    content: "";
    position: absolute;
    right: 0;
    top: -55px;
    width: 622px;
    height: 499px;
    background-image: url("/images/rightBg.png");
    background-size: cover;
    z-index: 0;
  } */
  @media (max-width: 1360px) {
    width: 100%;
    flex-direction: column;
    padding:16px 16px 0;
    margin-bottom: 40px;
    &::before{
      display: none;
    }
  }
`
const SlogonLeft = styled.div`
  display: flex;
  flex-direction: column;
  /* max-width: 746px; */
  max-width: 650px;
  gap: 24px;
  p{
    font-size: 64px;
    font-weight: 600;
    line-height: 80px;
    text-align: left;
    &.blue{
      background: linear-gradient(115.96deg, #0396FF 31.75%, #0468FC 100%);
      background-clip: text; 
      -webkit-background-clip: text; 
      -webkit-text-fill-color: transparent;
    }
  }
  @media (max-width: 1360px) {
    max-width: 100%;
    gap: 12px;
    /* margin-bottom: 40px; */
    p{
      font-size: 35px;
      font-weight: 600;
      line-height: 44px;
    }
  }
`
const SlogonRight = styled.div`
  width: 650px;
  height: 500px;
  @media (max-width: 1360px) {
    /* width: 100%;
    height: 254px; */
    display: none;
    }
`
const SlogonTxt = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  color: rgba(120, 124, 127, 1);
  @media (max-width: 1360px) {
    font-size: 10px;
  line-height: 24px;
  }
`
const DataList = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 1360px;
  gap: 24px;
  margin-bottom: 140px;
  @media (max-width: 1360px) {
    flex-wrap: wrap;
    width: 100%;
    padding:0 16px;
    gap: 12px;
    margin-bottom: 40px;
  }
`
const DataItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 32px 24px;
  gap: 24px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  img{
    display: inline-block;
    width: 30px;
    height: 30px;
  }
  .countDown{
    height: 28px!important;
    line-height: 28px!important;
  }
  @media (max-width: 1360px) {
    width: 47%;
    flex: none;
    padding: 16px 12px;
    gap: 12px;
    border-radius: 6px;
    img{
      width: 20px;
      height: 20px;
    }
    .countDown{
      height: 16px!important;
      line-height: 16px!important;
    }
  }
`
const DataInit = styled.p`
  font-size: 26px;
  font-weight: 500;
  height: 28px;
  line-height: 28px;
`
const Value = styled.p`
  font-size: 28px;
  font-weight: 500;
  line-height: 28px;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.2;
`
const Text = styled.p`
font-size: 14px;
font-weight: 400;
line-height: 14px;
color: rgba(120, 124, 127, 1);
@media (max-width: 1360px) {
  font-size: 10px;
  line-height: 8px;
}
;
`
const Exploring = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 16px;
  width: 1360px;
  margin-bottom: 96px;
  @media (max-width: 1360px) {
    width: 100%;
    flex-direction: column;
    gap: 8px;
    padding:0 16px;
    margin-bottom: 40px;
  }
`
const ExTitle = styled.p`
font-size: 48px;
font-weight: 600;
line-height: 48px;
@media (max-width: 1360px) {
  font-size: 20px;
  line-height: 20px;
}
`
const ExInfo = styled.div`
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
  /* padding: 16px 24px; */
  gap: 16px;
  /* border-radius: 12px;
  background: #fff; */
  color:#787C7F;
  
  text-align: center;
  @media (max-width: 1360px) {
    /* padding: 8px 12px; */
    gap: 10px;
    /* border-radius: 6px; */
  }
`
const ExPara = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  max-width: 1050px;
  @media (max-width: 1360px) {
    font-size: 10px;
    line-height: 24px;
  }
`
const ExContent = styled.p`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  flex-wrap: wrap;
`
const Detail = styled.div`
  display: flex;
  flex-direction: column;
  width: 437px;
  padding: 32px;
  gap: 24px;
  border-radius: 12px;
  background-color: #fff;
  @media (max-width: 1360px) {
    width: 100%;
    padding: 16px;
    /* gap: 12px; */
  }
`
const DetailPic = styled.img`
  width: 56px;
  height: 56px;
  @media (max-width: 1360px) {
    width: 32px;
    height: 32px;
  }
`
const DetailInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
  @media (max-width: 1360px) {
    gap: 6px;
  }
`
const DetailTitle = styled.p`
font-size: 18px;
font-weight: 600;
line-height: 18px;
@media (max-width: 1360px) {
  font-size: 14px;
  line-height: 14px;
}
`
const DetailPara = styled.p`
font-size: 16px;
font-weight: 400;
line-height: 28px;
color: rgba(121, 126, 132, 1);
text-align: left;
@media (max-width: 1360px) {
  font-size: 10px;
  line-height: 24px;
}
`

const FeaturedWarp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 80px 0px;
  gap: 60px;
  border-radius: 32px 32px 0px 0px;
  background-color: #fff;
  @media (max-width: 1360px) {
    padding: 40px 0;
    gap: 20px;
    border-radius: 0;
    margin-bottom: 40px;
  }
`
const Featured = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  width: 1360px;
  @media (max-width: 1360px) {
    width: 100%;
    flex-direction: column;
    padding:0 16px;
    gap: 20px;
  }
`
const FeaturedHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  @media (max-width: 1360px) {
    gap: 8px;
  }
`
const FHtitle = styled.p`
font-size: 48px;
font-weight: 600;
line-height: 48px;
@media (max-width: 1360px) {
  font-size: 20px;
  line-height: 20px;
}
`
const FHPara = styled.p`
  max-width: 865px;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  color: rgba(121, 126, 132, 1);
  text-align: center;
  @media (max-width: 1360px) {
    max-width: 100%;
    font-size: 10px;
    line-height: 24px;
  }
`
const FeaturedList = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  gap: 10px;
  @media (max-width: 1360px) {
    flex-direction: column;
    padding: 0;
    gap: 16px;
  }
`
const FeaturedItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 32px;
  gap: 24px;
  border-radius: 16px;
  background: rgba(248, 250, 255, 1);
  @media (max-width: 1360px) {
    width: 100%;
    flex: none;
    gap: 12px;
    padding: 16px;
    border-radius: 8px;
  }
`
const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  img{
    display: inline-block;
    width: 72px;
    height: 72px;
    @media (max-width: 1360px) {
      width: 37px;
      height: 37px;
    }
  }
`
const AssetsTag = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;
  border-radius: 4px;
  background: rgba(234, 241, 255, 1);
  color: rgba(2, 85, 254, 1)!important;
  cursor: pointer;
  @media (max-width: 1360px) {
    padding: 6px 8px;
    gap: 5px;
    border-radius: 2px;
  }
`
const ItemTitle = styled.p`
padding: 24px 0px;
gap: 10px;
font-size: 24px;
font-weight: 600;
line-height: 24px;
border-bottom: 1px solid rgba(0, 0, 0, 0.08);
@media (max-width: 1360px) {
  padding: 12px 0;
  font-size: 16px;
  line-height: 16px;
  gap: 5px;
}

`
const ItemPara = styled.p`
font-size: 16px;
font-weight: 400;
line-height: 32px;
color:rgba(121, 126, 132, 1);
@media (max-width: 1360px) {
  font-size: 10px;
  line-height: 24px;
}
`
const Ecosystem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 1360px;
  padding-top: 120px;
  @media (max-width: 1360px) {
    width: 100%;
    flex-direction: column;
    gap: 20px;
    padding:0 16px;
    margin-bottom: 40px;
  }
`
const EcoList = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  width: 100%;
  gap: 32px;
  @media (max-width: 1360px) {
    gap: 12px;
  }
`
const EcoItem = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 200px;
  height: 200px;
  padding: 40px 0;
  background-color: #fff;
  border-radius: 12px;
  margin: 0!important;
  cursor: pointer;
  img{
      width: 64px;
      height: 64px;
    }
  @media (max-width: 1360px) {
    gap: 20px;
    width: calc( (100% - 36px) / 3 );
    height: auto;
    padding: 25px;
    border-radius: 6px;
    img{
      width: 28px;
      height: 28px;
    }
  }
`
const EcoName = styled.div`
font-size: 16px;
font-weight: 400;
line-height: 16px;
@media (max-width: 1360px) {
  font-size: 9px;
  line-height: 9px;
}
`

const Governance = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  width: 1360px;
  padding-top: 120px;
  @media (max-width: 1360px) {
    width: 100%;
    flex-direction: column;
    padding:0 16px;
    margin-bottom: 40px;
    padding-top: 0;
  }
`
const GoverInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  margin-bottom: 160px;
  @media (max-width: 1360px) {
    gap: 20px;
    margin-bottom: 0;
  }
`
const GoverItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 120px;
  @media (max-width: 1360px) {
    flex-direction: column;
    gap: 20px;
    .BannerH5{
      order: 1;
    }
  }
`
const ItemLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
  gap: 24px;
  @media (max-width: 1360px) {
    flex: none;
    width: 100%;
    gap: 16px;
  }
`
const ItemTxt = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 1360px) {
    gap: 8px;
  }
`
const GoverItemTitle = styled.p`
font-size: 24px;
font-weight: 600;
line-height: 24px;
@media (max-width: 1360px) {
  font-size: 16px;
  line-height: 16px;
}
`
const GoverItemPara = styled.p`
font-size: 16px;
font-weight: 400;
line-height: 32px;
color:rgba(121, 126, 132, 1);
@media (max-width: 1360px) {
  font-size: 10px;
  line-height: 24px;
}
`
const Arrow = styled.a`
display: flex;
width: fit-content;
border-radius: 50%;
  img{
    display: inline-block;
    width: 40px;
    height: 40px;
  }
  @media (max-width: 1360px) {
    img{
      width: 25px;
      height: 25px;
    }
  }
`
const Banner = styled.img`
  display: flex;
  /* flex: 1; */
  width: 600px;
  @media (max-width: 1360px) {
    flex: none;
    width: 100%;
    height: auto;
  }
`
const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 1360px;
  @media (max-width: 1360px) {
    width: 100%;
    flex-direction: column;
    padding:0 16px;
    gap: 5px;
  }
`
const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1360px) {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }
`
const Navlist = styled.ul`
  display: flex;
  justify-content: space-between;
  gap: 120px;
  @media (max-width: 1360px) {
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 16px;
  }
`
const NavItem = styled.li`
  display: flex;
  flex-direction: column;
  @media (max-width: 1360px) {
    width: 30%;
  }
`
const Navdl = styled.dl`
font-size: 16px;
font-weight: 600;
line-height: 16px;
margin-bottom: 16px;
@media (max-width: 1360px) {
  font-size: 10px;
  line-height: 10px;
}
`
const Navdt = styled.dt`
font-size: 14px;
font-weight: 400;
line-height: 32px;
a{
  color: rgba(121, 126, 132, 1);
}
@media (max-width: 1360px) {
  font-size: 10px;
  line-height: 18px;
}
`
const IconList = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  gap: 16px;
  @media (max-width: 1360px) {
    gap: 8px;
    align-self: flex-start;
  }
`
const Icon = styled.a`
  img{
    display: inline-block;
  width: 36px;
  height: 36px;
  }
  @media (max-width: 1360px) {
    img{
      width: 24px;
      height: 24px;
    }
  }
`
const FooteBottom = styled.div`
  display: flex;
  justify-content: space-between;
padding: 24px 0px;
border-top: 1px solid rgba(0, 0, 0, 0.08);
@media (max-width: 1360px) {
  padding: 20px 0;
}
`
const Contact = styled.a`
font-size: 14px;
font-weight: 400;
line-height: 18px;
color: rgba(121, 126, 132, 1);
@media (max-width: 1360px) {
  font-size: 10px;
}
`
